import "./App.css";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import { Stack, Container, TextField, Button } from "@mui/material";

import ServiceCard from "./components/ServiceCard";
import LogoImg from "./assets/images/logo.png";
import biglogoImg from "./assets/images/biglogo.png";
import promotional from "./assets/images/promotional.png";
import shortlogo from "./assets/images/shortlogo.png";
import bag from "./assets/images/bag.png";
import card from "./assets/images/card.png";
import outdoor from "./assets/images/outdoor.png";
import offset from "./assets/images/offset.png";

const services = [
  {
    title: "OFFSET PRINTING",
    icon: offset,
    subtitle:
      "Brochures, Leaflets, Tent Cards, Stickers, Danglers, Calenders,Book Marks, Posters, Letter Heads, Visiting Cards, Envelopes, Handbills, News Letters, Invitation Cards, Certificates Magazines, Bill books, Quotations, Prescription Pads Table Calenders, Products List, Informative Letters, Reports, Boxes, Files",
  },
  {
    title: "OUTDOOR ITEMS",
    icon: outdoor,
    subtitle: "Flex Prinitng, Vinyl Prinngs, Standees, Sanpack, Gate Arch",
  },
  {
    title: "PROMOTIONAL ITEMS",
    icon: promotional,
    subtitle:
      "Caps, Coffee Mug, Pen, Pads, Mouse Pads, Watches, Mobile Covers, Photo Frames, Trophies, Gi Items, Gumming Tapes, Transparent Tapes with & without Prinng.",
  },
  {
    title: "BAGS",
    icon: bag,
    subtitle: "Paper Bags, Brown Paper Bags, Non Oven Bags, Cloth Bags ",
  },
  {
    title: "CARDS",
    icon: card,
    subtitle: "I Card, Card Holder, Lanyard, Yoyo",
  },
];
const FooterConatiner = styled.div`
  background: #edede3;
  margin-top: 80px;
  border-radius: 60px 60px 0px 0px;
`;
const CopyRightContainer = styled.div`
  background: #e9e9b3;
  padding: 18px;
  display: flex;
  font-weight: 800;
  font-size: 16px;
  justify-content: center;
  align-items: center;
`;
const TopContainer = styled.div`
  background: #fdfcdc;
  border-radius: 0px 0px 60px 60px;
  padding: 20px;
  box-shadow: 0px 9px 20px rgba(13, 38, 76, 0.19);
`;
const TitleText = styled.p`
  color: #f07167;
  font-weight: 800;
  font-size: 45px;
  margin-bottom: 0px;
`;
const HeadingText = styled.p`
  color: #2e5b99;
  font-weight: 800;
  font-size: 35px;
  margin-bottom: 40px;
  margin-top: 50px;
  text-align: center;
`;
const SubTitleText = styled.p`
  color: #000;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
`;
const SubTitleTextLi = styled.li`
  color: #000;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  line-height: 32px;
  cursor: pointer;
`;
const goToDiv = (divId) => {
  if (divId === "home") {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  } else {
    const getCourseElemt = document.getElementById(divId);
    if (getCourseElemt) {
      window.scrollTo({
        top: getCourseElemt.offsetTop,
        behavior: "smooth",
      });
    }
  }
};
function App() {
  return (
    <div className="App">
      <TopContainer>
        <Container maxWidth="lg">
          <header style={{ marginBottom: "40px" }}>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              spacing={3}
            >
              <img src={shortlogo} alt="aksahrshree" width="130px" />
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <SubTitleText
                  className="hide-on-phone"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={() => goToDiv("service")}
                >
                  Services
                </SubTitleText>
                <SubTitleText
                  className="hide-on-phone"
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={() => goToDiv("aboutUs")}
                >
                  About Us
                </SubTitleText>
                <Button
                  sx={{ borderRadius: "12px" }}
                  size="medium"
                  variant="contained"
                  onClick={() => goToDiv("getInTouch")}
                  endIcon={<SendIcon />}
                >
                  <span style={{ fontSize: "16px", fontWeight: "700" }}>
                    Send Enquiry
                  </span>
                </Button>
              </Stack>
            </Stack>
          </header>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack alignItems="center" justifyContent="center" height="100%">
                <img src={LogoImg} alt="Aksharshree" width="100px" />
                <TitleText>Aksharshree Graphics</TitleText>
                <SubTitleText>
                  Professional Printing And Graphic Design Services To Suit All
                  Your Printing Needs.
                </SubTitleText>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<dotlottie-player src="https://lottie.host/a140d8d2-a3ad-4af0-a5f2-fdf3196853ea/1EZhQJDN4D.json" background="transparent" speed="1" style="max-width: 600px;" loop autoplay></dotlottie-player>`,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </TopContainer>
      <Container maxWidth="lg">
        <div id="service">
          <HeadingText>Products and Services Offered</HeadingText>
          <Grid container spacing={5} justifyContent="center">
            {services &&
              services.map((service) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ServiceCard {...service} />
                </Grid>
              ))}
          </Grid>
        </div>
        <div id="aboutUs">
          <HeadingText>About US</HeadingText>
          <SubTitleText>
            Established in the year 2000, AKSHARSHREE in Nana Peth, Pune is a
            top player in the category Printing Press in the Pune. This
            well-known establishment acts as a one-stop destination servicing
            customers both local and from other parts of Pune. Over the course
            of its journey, this business has established a firm foothold in
            it’s industry. The belief that customer satisfaction is as important
            as their products and services, have helped this establishment
            garner a vast base of customers, which continues to grow by the day.
            This business employs individuals that are dedicated towards their
            respective roles and put in a lot of effort to achieve the common
            vision and larger goals of the company. In the near future, this
            business aims to expand its line of products and services and cater
            to a larger client base. In Pune, this establishment occupies a
            prominent location in Nana Peth. It is an effortless task in
            commuting to this establishment as there are various modes of
            transport readily available. It is at , Nivdung Vithoba Mandir Next
            To Hotel Choice Nr Parsi Agyari, which makes it easy for firsttime
            visitors in locating this establishment. It is known to provide top
            service in the following categories: Printing Press, Pamphlet
            Printers, Brochure Printers, Catalogue Printers, Offset Printers For
            Booklet, Offset Printers For Brochure, Book Printers, Offset
            Printers For Magazine
          </SubTitleText>
        </div>
        <div id="getInTouch">
          <HeadingText>Get In Touch</HeadingText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SubTitleText style={{ fontSize: "30px" }}>
                Enter the Details to Get in Touch
              </SubTitleText>
              <Stack spacing={2}>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  InputProps={{ sx: { borderRadius: "12px" } }}
                />
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="email"
                  InputProps={{ sx: { borderRadius: "12px" } }}
                />
                <TextField
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  type="number"
                  InputProps={{ sx: { borderRadius: "12px" } }}
                />
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  InputProps={{ sx: { borderRadius: "12px" } }}
                  multiline
                  rows={3}
                />
                <Button
                  sx={{ borderRadius: "12px" }}
                  size="medium"
                  variant="contained"
                >
                  <span style={{ fontSize: "18px", fontWeight: "700" }}>
                    Get in Touch
                  </span>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<dotlottie-player src="https://lottie.host/e2891daf-7654-4911-b0ff-eb569640c419/K6o6gdLXxG.json" background="transparent" speed="1" style="max-width: 600px;" loop autoplay></dotlottie-player>`,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
      <footer>
        <FooterConatiner>
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack justifyContent="flex-start">
                  <img src={biglogoImg} alt="Akasharshree" width="250px" />
                  <SubTitleText style={{ fontSize: "16px", textAlign: "left" }}>
                    AKSHARSHREE in Nana Peth has a wide range of products and
                    services to cater to the varied requirements of their
                    customers.
                  </SubTitleText>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack
                  alignItems={{
                    sm: "flex-start",
                    xs: "flex-start",
                    md: "flex-start",
                    lg: "center",
                  }}
                >
                  <SubTitleText style={{ fontSize: "22px", fontWeight: "800" }}>
                    Important Links
                  </SubTitleText>
                  <ul>
                    <SubTitleTextLi onClick={() => goToDiv("home")}>
                      Home
                    </SubTitleTextLi>
                    <SubTitleTextLi onClick={() => goToDiv("aboutUs")}>
                      About Us
                    </SubTitleTextLi>
                    <SubTitleTextLi onClick={() => goToDiv("service")}>
                      {" "}
                      Services
                    </SubTitleTextLi>
                    <SubTitleTextLi onClick={() => goToDiv("getInTouch")}>
                      Send Enquiry
                    </SubTitleTextLi>
                  </ul>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Stack
                  alignItems={{
                    sm: "flex-start",
                    xs: "flex-start",
                    md: "flex-start",
                    lg: "center",
                  }}
                >
                  <SubTitleText style={{ fontSize: "22px", fontWeight: "800" }}>
                    Contact Us
                  </SubTitleText>
                  <SubTitleText
                    style={{
                      fontSize: "16px",
                      margin: "0px",
                      textAlign: "left",
                    }}
                  >
                    Registered Address: 612 B/1, Nana Peth, Latesh Soc., Next to
                    Hotel Choice, Near Parsi Agyari, Pune - 411002
                  </SubTitleText>
                  <SubTitleText
                    style={{
                      fontSize: "16px",
                      margin: "0px",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Email :{" "}
                    <a
                      target="_blank"
                      href="mailto:aksharshree@gmail.com"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      aksharshree@gmail.com
                    </a>
                  </SubTitleText>
                  <SubTitleText
                    style={{
                      fontSize: "16px",
                      margin: "0px",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    Phone :{" "}
                    <a
                      target="_blank"
                      href="tel:+919822114846"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      9822114846 / 8408807556
                    </a>
                  </SubTitleText>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </FooterConatiner>
        <CopyRightContainer>
          <a
            href="https://prasenjit-26.github.io/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "rgb(80 78 78)" }}
          >
            {" "}
            2022 © Developed By Prasenjit Kaninde
          </a>
        </CopyRightContainer>
      </footer>
    </div>
  );
}

export default App;
