import { Stack, Button } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styled from "styled-components";
const ServiceContainer = styled.div`
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #f07167;
  box-shadow: 0px 9px 20px rgba(13, 38, 76, 0.19);
`;

const TitleText = styled.p`
  color: #f07167;
  font-weight: 800;
  font-size: 25px;
  margin-bottom: 0px;
`;

const SubTitleText = styled.li`
  color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: left;
`;

export default function ServiceCard({ title, icon, subtitle }) {
  let items = [];
  let defautltShowLimit = 4;
  const [showMore, toggleMore] = React.useState(false);
  if (subtitle) {
    items = subtitle.split(",");
  }
  const toggleShowMore = () => {
    toggleMore(!showMore);
  };
  let leftItems = items.slice(0, Math.ceil(items.length / 2));
  let rightItems = items.slice(Math.ceil(items.length / 2));
  if (!showMore) {
    leftItems = leftItems.slice(0, Math.ceil(defautltShowLimit / 2));
    rightItems = rightItems.slice(0, Math.ceil(defautltShowLimit / 2));
  }
  return (
    <ServiceContainer>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <img src={icon} alt="aksharshree" width="80px" />
        <TitleText>{title}</TitleText>
        <Stack
          spacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="row"
        >
          <ul>
            {leftItems.map((item) => (
              <SubTitleText>{item}</SubTitleText>
            ))}
          </ul>
          <ul>
            {rightItems.map((item) => (
              <SubTitleText>{item}</SubTitleText>
            ))}
          </ul>
        </Stack>
        {items.length > defautltShowLimit && (
          <Button
            sx={{ border: "1px solid #2e5b99", borderRadius: "15px" }}
            variant="outlined"
            onClick={toggleShowMore}
            endIcon={
              showMore ? (
                <ExpandLessIcon sx={{ color: "#2e5b99" }} />
              ) : (
                <ExpandMoreIcon sx={{ color: "#2e5b99" }} />
              )
            }
          >
            <span style={{ color: "#2e5b99" }}>
              {showMore ? "Show Less" : "Show More"}
            </span>
          </Button>
        )}
      </Stack>
    </ServiceContainer>
  );
}
